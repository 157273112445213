import React from "react";
import { Link } from "gatsby";
import Footer from "../components/Footer";
import SEO from "../components/seo";

export default function About() {
  return (
    <div className="About">
      <SEO />
      <nav className="Navbar">
        <Link to="/">
          <h3 className="logo">{'<stm/>'}</h3>
        </Link>
      </nav>
      <section className="introduction">
      <p className="my-name">Hi, I'm Scotty McCormick</p>
    </section>
      
      <section className="about-me">
        <p>I am a web developer with 3+ years of experience.</p>
        <p>Building sites with React, Express, Node is my day-to-day gig.</p>
        <p>Lately I've been using Next.js, Redux, Less, GTM, PreBid, MongoDB.</p>
        <p>My work is about 75% front end, with some basic AWS management, data fetching, and dealing with third-party pixels as well</p>
        <p>In my personal projects, I've been focused on Next.js, Tailwind, and brushing up on my SQL DB querying.</p>
        <p>This site is (recently) rebuilt in Gatsby and deployed on Netlify</p>
        <hr/>
        <p>I received by B.S. in Mechanical Engineering and then stepped into the world of building web applications. The trouble is, I quite like it. With some exposure to Java, MATLAB, and C under my belt, I decided to commit to software development full time.</p>
        <p>I started off building web applications with the SAPUI5 framework, working directly with client stakeholders to design and build from the ground up. Then, I brushed up on my skills at the General Assembly coding bootcamp.</p>
        <hr/>
        <p>I live in beautiful Alameda, CA and I work in the San Francisco Bay Area.</p>
      </section>
      <Footer />
    </div>
  )
}